import React from "react"
import { GatsbyImage } from "gatsby-plugin-image";


const MeterLink = ({ meter }) => {
    let featuredImgFluid = meter.frontmatter.featuredImage.childImageSharp.gatsbyImageData
    return (
        <div className="col-lg-12 col-md-6">
            <div className="single-blog-post">
                <div className="row">
                    <div className="col-lg-2 col-md-6">
                        <div className="post-image">
                                <GatsbyImage image={featuredImgFluid} alt="Meter" />
                        </div>
                    </div>
                    <div className="col-lg-10 col-md-6">
                        <h3> {meter.frontmatter.title} </h3>

                        <div dangerouslySetInnerHTML={{ __html: meter.html }} />

                    </div>
                </div>
            </div>
        </div>

    );
}

export default MeterLink

